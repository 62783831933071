const projects = [
  {
    slug: "relais-&-chateaux-press-office",
    title: [
      {
        copy: "Relais & Châteaux Press Office",
        size: "35",
      },
    ],
    image: require("@/assets/projects/relais-&-chateaux-press-office.png"),
    category: ["PR"],
    introText:
      "Q Communications was appointed to handle the PR for Relais & Châteaux, one of the largest association of individually owned and operated luxury hotels and restaurants across the world.",
    fullArticle: `<p>
        Q Communications was appointed to handle the PR for Relais & Châteaux, one of the largest association of individually owned and operated luxury hotels and restaurants across the world. The two-year project consisted of a tenacious media outreach programme, targeting the discerning traveller across the UK and Ireland; with the aim of asserting Relais & Châteaux’s values and commitment for a better world by offering exclusive experiences to the press, and in turn showcasing Relais & Châteaux’s unique hospitality and cuisine. Our brief was to support the Relais & Châteaux brand, as well as the portfolio of 580 properties within the association, working closely with head office, as well as the individual properties within each market to create interesting and engaging stories.<br/><br/>
        The press office function included feature placement, competitions, content creation, press briefings, travel round ups, trade and industry media relations, interviews, trend led commentary, individual press visits and group press trips, as well as campaign events designed to provide rich topical content for travel and food journalists in the UK. Over the course of the two year project, numerous pieces of brand and property level coverage were achieved in high end media publications for the UK & Ireland properties, as well as the international ones, including The Times, The Daily Telegraph, Financial Time, The Independent, Evening Standard, The Metro, GQ, Conde Nast Traveller, Bazaar, Red, Country & Townhouse, Robb Report. <br/><br/>
        Our work helped to move Relais & Châteaux away from some historic preconceptions of being a brand for the older, traditional traveller. By working with lifestyle publications on angles and topics which followed the trends, we were able to challenge these views and drive interest and bookings from an audience which provided a much wider opportunity for the brand to grow. Brand partnerships were also explored as another route in which to do this.
      </p>`,
  },
  {
    slug: "heritage-store-new-product-launch-event",
    title: [
      {
        copy: "Heritage Store",
        size: "35",
      },
      {
        copy: "New Product Launch Event",
        size: "35",
      }
    ],
    image: require("@/assets/projects/heritage-store-new-product-launch-event.jpg"),
    category: ["PR"],
    introText:
      "Q Communications worked with the Heritage Store head office team in the US, as well as the European team to launch the cult skincare brand, known for its soul-to-skin beauty, into the UK market",
    fullArticle: `<p>
        Q Communications worked with the Heritage Store head office team in the US, as well as the European team to launch the cult skincare brand, known for its soul-to-skin beauty, into the UK market. The launch generated blanket coverage across women’s and men’s lifestyle media, teen publications, as well as specialist beauty titles. After working with Heritage Store for several months, to celebrate the brand’s Superdrug listing, and range of new products – clay mask, moisturiser, cleanser - Q hosted a dedicated press briefing and lunch at Sommerset House in London for key beauty media to visit and experience the brand first-hand.  The team from the US flew in from New York and The Netherlands especially for the event so that one to one interviews with the media could be set up during the event. Buyers from Superdrug were also in attendance to answer any questions about their partnership with Heritage Store. <br/><br/>
        The Q Communications team coordinated the full event production start to finish, from the venue dressing which included a floor to ceiling flower wall, complete with neon Heritage Store sign and a product display which communicated Heritage Store’s clean and natural ingredients, to the menu design, table decorations, seating plan and the video presentation.<br/><br/>
        With 30 attendees secured and in attendance, including key beauty editors from Grazia, Elle, Woman & Home, Good Housekeeping, Prima, Red, Cosmopolitan, Stylist and The Sun, the event resulted in excellent feedback from the media and strong product coverage which showcased Heritage Store’s key messages of being vegan, cruelty free, soothing and healing. Each piece of coverage had a clear call to action and included the retailer website, driving readers to purchase. As a direct result of the event and the coverage achieved, Heritage Store saw a steady increase in sales and brand awareness increased significantly.
      </p>`
  },
  {
    slug: "northcote-hotel-&-michelin-star-restaurant-press-office",
    title: [
      {
        copy: "Northcote Hotel & Michelin",
        size: "35",
      },
      {
        copy: "Star Restaurant Press Office",
        size: "35",
      },
    ],
    image: require("@/assets/projects/northcote-hotel-&-michelin-star-restaurant-press-office.jpg"),
    category: ["PR"],
    introText:
      "Located in the Lancashire’s Ribble Valley, surrounded by miles of unspoiled and glorious countryside, sits the treasured boutique hotel Northcote.",
    fullArticle: `
      <p>
        Located in the Lancashire’s Ribble Valley, surrounded by miles of unspoiled and glorious countryside, sits the treasured boutique hotel Northcote. Its Michelin-starred restaurant, headed up by one of the most innovative and exciting chefs in the UK today Lisa Goodwin-Allen - 2022 CATEY Chef of the Year, AYALA SquareMeal Female Chef of the Year 2022 and regular guest on James Martin’s Saturday Morning and BBC Morning Live, judge and mentor on MasterChef: The Professionals and Celebrity MasterChef, as well as BBC1’s Great British Menu - has long been known for its adventurous menus using seasonal ingredients available to the restaurant. <br/><br/>
        Q Communications began working with Northcote just before the pandemic hit, and supported the property through one of the most difficult periods the business and hospitality industry had ever faced. Ever the innovative Northcote launched what came to be known as the ‘most sought after at gourmet meal kits’ in the country after Q launched the kits to the media via a strategic review programme and achieved wide spread coverage. Q now runs the property’s press office, securing regular local, regional, national and industry features, reviews, press visits and roundup coverage, generating excellent ‘third party’ validation, here in the UK that sets Northcote apart from its competitors. Q ensures that Northcote is always on the forefront of the minds of industry influencers and is included in listicles. This earned media has enabled us to increase Northcote’s visibility, using a solid foundation of engaging and shareable storytelling focusing on the team, the menus, sustainability, key trends, milestones, accolades and awards.
      </p>`,
  },
  {
    slug: "gilpin-hotel-&-lake-house-media-and-influencer-engagement",
    title: [
      {
        copy: "Gilpin Hotel & Lake House Media",
        size: "35",
      },
      {
        copy: "and Influencer Engagement",
        size: "35",
      },
    ],
    image: require("@/assets/projects/gilpin-hotel-&-lake-house-media-and-influencer-engagement.png"),
    category: ["PR"],
    introText:
      "Located in the beautiful Lake District, Gilpin Hotel & Lake House is an elegant, relaxing haven of luxury which offers the ultimate in wellness experiences.",
    fullArticle: `<p>
      Located in the beautiful Lake District, Gilpin Hotel & Lake House is an elegant, relaxing haven of luxury which offers the ultimate in wellness experiences. The property is dedicated to nourishment, recuperation and relaxation, and is made up of two hotels on two separate estates, just a mile away from each other. At the end of 2022, Gilpin Hotel & Lake House announced its plans to completely transform its F&B offering making the way for it to become THE foodie destination of the region with the opening of a new restaurant at Gilpin Lake House and the appointment of three high-profile chefs; Ollie Bridgwater (ex Fat Duck), Tom ‘Westy’ Westerland (ex Lucknam Park) and Aakash Ohol (ex HRiSHi) to oversee its three restaurants. <br/><br/>
      Q has been working with the property since 2021 and was brought on to drive media and influencer engagement, ensuring that there was a constant stream of top tier media coverage for the hotel and Michelin star restaurant via press reviews, media 121s, chef profiling, recipe and interview placement. <br/><br/>
      For the announcement of the three new chefs, Q devised a media engagement strategy which included exclusive interviews with The Caterer and Lancashire & Lake District Life, resulting in several page features. We also ensured that the news was shared with all relevant wider key media to create buzz and anticipation for the new appointments. Once each chef settled into their roles and menus and concepts were established, Q embarked on a media outreach programme designed to allow key food and travel writers to experience the new menus for themselves.
      </p>`,
  },
  {
    slug: "northcote-obsession",
    title: [
      {
        copy: "Northcote Obsession",
        size: "35",
      },
    ],
    image: require("@/assets/projects/northcote-obsession.jpg"),
    category: ["PR"],
    introText:
      "Known as one of the most prestigious and internationally recognised gourmet food festivals in Europe, Northcote hotel’s Obsession launched in 2001 as a celebration of great food, wine and a meeting of friends, old and new.",
    fullArticle: `<p>
      Known as one of the most prestigious and internationally recognised gourmet food festivals in Europe, Northcote hotel’s Obsession launched in 2001 as a celebration of great food, wine and a meeting of friends, old and new. From that first event, it has evolved but the ethos is still very much the same; to offer guests a completely memorable culinary experience, created by some of the world’s most innovative chefs. <br/><br/>
      For Obsession 22, following a ‘year out’ during the pandemic, Q’s promotion of the festival was taken up a level. To create buzz and create demand, with the key goal of selling out the event within a couple of days of going on sale, just ahead of release date, Q arranged and managed a virtual press conference to announce the chef line up and share booking details. The conference was hosted by Northcote’s executive chef Lisa Goodwin-Allen, and Obsession 22 chefs Tom Kerridge (Hand & Flowers) and Matt Abe (Restaurant Gordon Ramsay) joined her to help announce the lineup of chefs and take part in a media Q&A. The conference was attended by some of the UK’s top food and industry journalists and coverage of Obsession 22 was achieved across all key titles. The wide reach and success of the launch resulted in tables selling out in record time, with thousands of people registering interest for a table.<br/><br/>
      In the lead up to the event, a fun and insightful video content campaign, counting down to opening night was launched in order to create excitement and engagement with Northcote’s social media community. Q asked all of the chefs taking part to answer a series of fun and insightful questions about themselves and their nights which we put together in a series of videos, these were then shared across Northcote’s social media platforms.
      </p>`,
  },
  {
    slug: "relais-&-chateaux-world-oceans-day",
    title: [
      {
        copy: "Relais & Châteaux’s",
        size: "35",
      },
      {
        copy: "World Oceans Day",
        size: "35",
      },
    ],
    image: require("@/assets/projects/relais-&-chateaux-world-oceans-day.jpg"),
    category: ["PR", "Content Creation"],
    introText:
      "To create awareness of Relais & Châteaux’s World Oceans Day campaign and create anticipation ahead of World Oceans Day on the 8th June, Q arranged for five of Relais & Châteaux’s most exciting and innovative chefs to come together for a ‘SEAWEED SYMPHONY’ press preview event at chef Michael Caines' Lympstone Manor on the Exe Estuary in Devon.",
    fullArticle: `<p>
      To create awareness of Relais & Châteaux’s World Oceans Day campaign and create anticipation ahead of World Oceans Day on the 8th June, Q arranged for five of Relais & Châteaux’s most exciting and innovative chefs to come together for a ‘SEAWEED SYMPHONY’ press preview event at chef Michael Caines' Lympstone Manor on the Exe Estuary in Devon. Using key learnings from previous World Oceans Day campaigns, Q ensured that there were strong UK & Ireland touch points and statistics which provided relevance for the media in attendance. <br/><br/>
      The two day trip which aimed to raise awareness about the sustainable use of marine resources included a gala feast that showcased the delicious beauty and bounty of the sea. The a one-of-a-kind five-course menu was jointly created by Michael Caines, Gareth Stevenson from Palé Hall, Aaron Patterson from Hambleton Hall, James Coffey from Park Hotel Kenmare and Calum Innes from Airds Hotel and Restaurant. Ahead of the dinner, a press briefing was held to communicate Relais & Châteaux’s key goals of the campaign, and sustainable messages. A trip to see a catch at the docks was also included and media were able to meet with local fishermen to understand the importance of protecting our oceans. <br/><br/>
      The press trip provided a vast amount of content opportunities for the media and with two months lead up time to World Oceans Day, we were able to work with the media in attendance including The Caterer and The Independent, to create some really strong features that would appear the week of World Oceans Day. The ‘newsworthy’ event was an excellent backdrop to communicate the key messages of the campaign, and gave media access to key spokespeople.
      </p>`,
  },
  {
    slug: "relais-&-chateaux-food-for-change",
    title: [
      {
        copy: "Relais & Châteaux’s",
        size: "35",
      },
      {
        copy: "Food for Change",
        size: "35",
      },
    ],
    image: require("@/assets/projects/relais-&-chateaux-food-for-change.jpg"),
    category: ["PR"],
    introText:
      "To drive awareness of Relais & Châteaux’s Food for Change campaign, in partnership with Slow Food, in November 2022, Q hosted a press trip to Relais & Châteaux property Cashel Palace in Tipperary in Ireland for UK and Irish based media where Stephen Hayes of Cashel Palace, Sam Moody of Ballyfin Demesne and Mark Treacy of Sheen Falls created a culinary celebration of the Ark of Taste.",
    fullArticle: `<p>
      To drive awareness of Relais & Châteaux’s Food for Change campaign, in partnership with Slow Food, in November 2022, Q hosted a press trip to Relais & Châteaux property Cashel Palace in Tipperary in Ireland for UK and Irish based media where Stephen Hayes of Cashel Palace, Sam Moody of Ballyfin Demesne and Mark Treacy of Sheen Falls created a culinary celebration of the Ark of Taste.<br/><br/>
      The aim of the 2022 Food for Change campaign was to promote regenerative farming practices among Relais & Châteaux’s members and their wider communities, in order to help to restore the planet’s ecosystems and fight against climate change. The trip to Cashel Palace allowed media to witness how the campaign was working in practice. Q showcased the ways in which the chefs are inspired by their locations and how they are embracing regenerative farming. Tasked with using the 14 Irish ingredients listed in the Ark of Taste, the chefs from the Relais & Châteaux Irish portfolio put together a six-course feast showcasing regenerative agriculture which was not only attended by the media but by guests from all over Ireland. <br/><br/>
      Following the dinner, the following morning we held a Q&A with the chefs and the media. The Q&A was hosted by Michael Caines and highlighted the campaign brand messages, clearly demonstrating what the Relais & Châteaux properties in Ireland were doing to support the initiative. We then took the media to meet one of Cashel Palace’s local suppliers, Irish Farmhouse Cheesemakers to learn all about the history of Cashel Blue which was an ingredient served in one of the dishes the night before. Attended by journalists from Delish, Elle, Cosmopolitan, Esquire, Red, Good Housekeeping, Irish Independent, Irish Times and The Gloss, the trip resulted in a lot of food for thought, and coverage that sparked conversation and debate. 
      </p>`,
  },
  {
    slug: "profiling-michelin-star-chef-lisa-goodwin-allen",
    title: [
      {
        copy: "Profiling Michelin Star",
        size: "35",
      },
      {
        copy: "Chef Lisa Goodwin-Allen",
        size: "35",
      },
    ],
    image: require("@/assets/projects/profiling-michelin-star-chef-lisa-goodwin-allen.jpg"),
    category: ["Influencer and Celebrity"],
    introText:
      "Lisa Goodwin-Allen is a British chef best known for being executive chef of the Michelin-starred Northcote restaurant.",
    fullArticle: `<p>
      Lisa Goodwin-Allen is a British chef best known for being executive chef of the Michelin-starred Northcote restaurant. She was also one of four winning chefs on season five of the BBC cooking show Great British Menu. Q was brought on board to work closely with Lisa to help strengthen her personal profile, elevate her as an individual and drive recognition in order to grow opportunities. Q set about developing brand partnerships, securing profile and comment pieces in the national newspapers, and positioning her as one of the UK’s leading chefs. Collaborations with Soneva in the Maldives and the Coco Collection for chef residencies were established as part of the campaign, as well as profile pieces in the The Independent, Olive and Delicious magazines.  
    </p>`,
  },
  {
    slug: "heritage-store-influencer-marketing",
    title: [
      {
        copy: "Heritage Store",
        size: "35",
      },
      {
        copy: "Influencer Marketing",
        size: "35",
      },
    ],
    image: require("@/assets/blogs/blog-3.jpeg"),
    category: ["Influencer and Celebrity"],
    introText:
      "Since the summer of ‘69, Heritage Store has been on the journey of soul-to-skin beauty. When the American cult brand landed on UK soil and achieved a listing on Cult Beauty, the brand wanted to create lots of buzz and drive as many sales as possible to the online retailer.",
    fullArticle: `<p>
      Since the summer of ‘69, Heritage Store has been on the journey of soul-to-skin beauty. When the American cult brand landed on UK soil and achieved a listing on Cult Beauty, the brand wanted to create lots of buzz and drive as many sales as possible to the online retailer. Q embarked on an organic micro-influencer campaign, building relationships with key beauty, mummy, lifestyle and sustainable skincare influencers and sharing the products with them in order to increase reach and engagement, as well as build awareness of the products.<br/><br/>
      Q collaborated with micro skincare influencers who shared the same values and ethics as Heritage Store. It was important that the influencers we worked with, and their followers, had a genuine interest in clean skincare and wanted to actively support minimal ingredients, ones that would talk about the story of the brand in an engaging and credible way. We looked for influencers who were passionate about clean and vegan skincare, inviting them to try the products and if they liked it, and it was effective for them, share branded content about the Heritage Store product explaining why they liked it and how the product benefitted their skin. Once we had a found our influencers, we invited them to speak to their audience and share educational reels and TikToks about the Heritage Store products. A number of giveaways were also set up as a sampling exercise to drive brand awareness.<br/><br/>
      Over the duration of the 6-month campaign, more than 100 posts, stories, reels and vlogs were shared recommending the brand, resulting in a reach of more than 20 million. The content was shareable and engaging, and focussed on the key benefits of the ingredients.
    </p>`,
  },
  {
    slug: "lecrans-hotel-&-spa-social-media-management",
    title: [
      {
        copy: "LeCrans Hotel & Spa",
        size: "35",
      },
      {
        copy: "Social Media Management",
        size: "35",
      },
    ],
    image: require("@/assets/projects/lecrans-hotel-&-spa-social-media-management.jpg"),
    category: ["Digital", "Social Media"],
    introText:
      "Q Communications was appointed by LeCrans Hotel & Spa in Switzerland to transform its Instagram and Facebook presence.",
    fullArticle: `<p>
      Q Communications was appointed by LeCrans Hotel & Spa in Switzerland to transform its Instagram and Facebook presence. A ski-in, ski-out lodge and wellness destination with a Michelin-starred restaurant and breath-taking panoramic views, nestled within the lush Alpine forests of the exclusive mountain resort of Crans-Montana in Valais, the property’s social media platforms didn’t communicate the reality of the guest experience. Q worked with the team on a content strategy, designed to attract LeCrans’ target audience at every stage of the social media journey with the ultimate goal of driving occupancy. Q embarked on a complete overhaul of the style and tone of voice of the Instagram and Facebook accounts, creating a Social Media Playbook that acted as a clear set of guidelines for all social media activity, ensuring that everyone was on the same page. A bank of strong video and image content that conveyed the essence of the brand and appealed to the affluent target market was then generated.<br/><br/>
      A highly targeted and full-circle Facebook and Instagram advertising campaign was also launched, targeting potential guests in the key feeder markets; UK, France, Germany as well as the domestic audience in Switzerland. A fan acquisition campaign ensured that growth of the Facebook page was consistent, and boosted content meant that posts were able to reach an engaged audience. Q Comms achieved exceptional results in a short time, increasing page engagement by 87%, reach by 73% and profile visits by 92%.
    </p>`,
  },
  {
    slug: "light-touch-clinic-social-media-management",
    title: [
      {
        copy: "Light Touch Clinic",
        size: "35",
      },
      {
        copy: "Social Media Management",
        size: "35",
      },
    ],
    image: require("@/assets/projects/light-touch-clinic-social-media-management.jpg"),
    category: ["Digital", "Social Media"],
    introText:
      "Q Communications was brought onboard with Light Touch Clinic to drive its online content strategy, increase awareness of the clinic and establish the brand as an expert in non-surgical aesthetic treatments.",
    fullArticle: `<p>
      Q Communications was brought onboard with Light Touch Clinic to drive its online content strategy, increase awareness of the clinic and establish the brand as an expert in non-surgical aesthetic treatments.<br/><br/>
      Light Touch Clinic, a multi award-winning cosmetic medical clinic in Weybridge in Surrey, with a special focus on skin care, light touches and weight management, the clinic prides itself on providing the most innovative and proven treatments to patients. Q worked with Light Touch Clinic on retained bases, managing the clinic’s social media platforms and content creation. Q worked with the team to develop strategies to increase followers, created and oversaw social campaigns, produced photography, videography and written content, reviewed analytics and communicated with key stakeholders. All content was fully optimised for syndication across the clinic’s social media platforms. Tracking analytics and reporting was very important for Light Touch Clinic and we were able to provide, via Google Analytics, the conversion rate and effectiveness of the content and social media advertising campaigns. The social media platforms proved to be one of the leading streams of website traffic and generated strong leads and sales for the business. 
    </p>`,
  },
  {
    slug: "solaray-social-media-photography",
    title: [
      {
        copy: "Solaray Social",
        size: "35",
      },
      {
        copy: "Media Photography",
        size: "35",
      },
    ],
    image: require("@/assets/projects/solaray-social-media-photography.jpg"),
    category: ["Photography", "Videography"],
    introText:
      "Solaray began manufacturing and selling vitamins and herbal products in 1973 and has since become one of the largest health food manufacturers in the world.",
    fullArticle: `<p>
      Solaray began manufacturing and selling vitamins and herbal products in 1973 and has since become one of the largest health food manufacturers in the world. Q Communications was briefed to generate rich and shareable product lifestyle photography content for Solaray to use and repurpose across its social media platforms globally; the aim being to show the Solaray brand as a whole and demonstrate how it can fit into the consumer's everyday life, rather than it be too focused on the product and out of context. The shoot, which took place over three days and at four different locations, including in the studio, in the Lake District surrounded by nature and in an apartment helped the consumer to see what lifestyle the Solaray brand promotes - to Live Brighter. Q managed the full production of the photoshoot, from sourcing the locations, models, studio and props, as well as acting as the creative director at the shoot and managed all of the styling and set up. What resulted was high quality, engaging images which achieved a high rate of shareability, which in turn drove reach and engagement across the social media platforms.
    </p>`,
  },
  {
    slug: "heritage-store-new-product-launch-videography",
    title: [
      {
        copy: "Heritage Store New",
        size: "35",
      },
      {
        copy: "Product Launch Videography",
        size: "35",
      },
    ],
    image: require("@/assets/projects/heritage-store-new-product-launch-videography.jpg"),
    video: '6uZBuHEtmb8',
    category: ["Photography", "Videography"],
    introText:
      "Video content outperforms any other type of social media content there is, it can grow audiences, engage the community and turn views and clicks into sales.",
    fullArticle: `<p>
      Video content outperforms any other type of social media content there is, it can grow audiences, engage the community and turn views and clicks into sales. This is why, when we held a PR event for American cult skincare brand Heritage Store in London, we made sure that we captured every moment of it in video format in order to be able share it with Heritage Store's social media community, giving people a glimpse of the new range of products that would be going into store, as well as an insight into what goes on behind the scenes of a product launch event. The video added a new dimension to the Heritage Store brand and we were able to increase search visibility by using headlines, descriptions and hashtags to optimise the video for search, which helped to improve the brand's ranking on Google. The video proved to be a big hit with the social media community with more than 8k organic views, with 25% of people clicking through to the retailer's website.
    </p>`,
  },
  {
    slug: "light-touch-clinic-social-media-videography",
    title: [
      {
        copy: "Light Touch Clinic",
        size: "35",
      },
      {
        copy: "Social Media Videography",
        size: "35",
      },
    ],
    image: require("@/assets/projects/light-touch-clinic-social-media-videography.jpg"),
    video: 'pvtPVXZ0EJ4',
    category: ["Photography", "Videography"],
    introText:
      "Video production is an integral part of Light Touch Clinic's social media marketing strategy.",
    fullArticle: `<p>
      Video production is an integral part of Light Touch Clinic's social media marketing strategy. Insight and understanding of the aesthetic treatment consumer, as well as primary and secondary research enabled us to pinpoint the exact type of content that the target audience was engaging with and what barriers to purchase they had. With this knowledge, we worked closely with the team at Light Touch Clinic in Surrey to produce a bank of highly educational and myth busting video content that answered FAQs in a simple way. The videos showed treatments being carried out, put a friendly faces to those carrying out the treatments and most importantly, provided the viewers with the results. When shared across the social media platforms, the videos out performed all other content. Due to the high quality and professionalism demonstrated within the videos we were able to use them in a social media advertisement campaign too and more than 500 conversions were achieved.
    </p>`,
  },
];

export { projects };
